import * as React from "react";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { Link } from "react-router-dom";
import { LayoutActions } from "../../../layout/actions/layoutActions";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { TagmanagerUtils } from "../../utils/tagmanagerUtils";

interface Props {
  isAccepted: "n" | "y" | null;
}

class CookieBanner extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onCookieBannerOptionNo = this.onCookieBannerOptionNo.bind(this);
    this.onCookieBannerOptionYes = this.onCookieBannerOptionYes.bind(this);
  }

  gtag = (...args) =>  {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(args);
  }

  gtagInit() {
    this.gtag('consent', 'default', {
      "ad_personalization": "denied",
      "ad_storage": "denied",
      "ad_user_data": "denied",
      "analytics_storage": "denied",
    });
  }

  //todo: add GTM_ID in the .env file
  componentDidMount() {
    const loadGtagScript = () => {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=GTM-K65BGM3`;
      script.async = true;
      document.head.appendChild(script);

      this.gtag({ "js": new Date() });
      this.gtag({ "config": "GTM-K65BGM3" });
      this.gtagInit();
    };
    loadGtagScript()
  }

  onCookieBannerOptionNo() {
    ElstrCore.store.dispatch(LayoutActions.acceptCookies("n"));
  }

  async onCookieBannerOptionYes() {
    ElstrCore.store.dispatch(LayoutActions.acceptCookies("y"));
    await TagmanagerUtils.init();
    await this.gtag('consent', 'update', {
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
  }

  render() {
    if (this.props.isAccepted !== null) {
      return null;
    }
    return (<div className="cookie__banner">
      <p className="cookie-banner__title">{dic("COOKIE CONSENT")}</p>
      <p className="cookie-banner__text">
        {dic("COOKIEBANNER")}{" "}
        <Link
          className="cookie-banner__link"
          to={`/cms/${activeLang()}/${dic("CMSURL_DATENSCHUTZ")}`}
        >
          {dic("DATENSCHUTZERKLAERUNG")}
        </Link>
      </p>
      <button className="btn reset" onClick={this.onCookieBannerOptionNo}>{dic("COOKIE_OPTION_NO")}</button>
      <button className="btn" onClick={this.onCookieBannerOptionYes}>{dic("COOKIE_OPTION_YES")}</button>
    </div>)
  }
}

export default CookieBanner;
