import { OrderDataVo } from "../../State";
import { OrderListProductVo } from "../vo/OrderVo";
import ProductVo from "../vo/ProductVo";
import TagManager from "react-gtm-module";

declare const dataLayer: any;

export const TagmanagerUtils = {
  pushEcommercePurchase: function(orderData: OrderDataVo) {
      const sumOfProductsPrices = orderData.sumOfProductsPrices;
      const sum = sumOfProductsPrices + orderData.shippingCost;
      const mwst = sum * (orderData.vatRate / 100);

      const products = orderData.listProducts.map((product: OrderListProductVo) => {
        const productData: ProductVo = product.baselineProductDataJson;
        return {
          name: productData.ShortDescription,
          id: productData.objectID,
          price: product.pricePerUnit.toFixed(4),
          brand: productData.herstellername,
          category: productData.funktion,
          quantity: product.quantity,
        };
      });

      const objectToPush = {
        event: "ecommerce",
        ecommerce: {
          currencyCode: orderData.dkoIsocode,
          purchase: {
            actionField: {
              id: orderData._id, // Transaction ID. Required for purchases and refunds.
              affiliation: "Webshop",
              revenue: orderData.totalPrice.toFixed(4), // Total transaction value (incl. tax and shipping)
              tax: mwst.toFixed(4),
              shipping: orderData.shippingCost.toFixed(4),
            },
            products,
          },
        },
      };

      // console.log(objectToPush);
      dataLayer.push(objectToPush);
      return true;
  },

  init: function() {
      const tagManagerArgs = {
        gtmId: 'GTM-K65BGM3',
      };

      // Google Tag Manager
      TagManager.initialize(tagManagerArgs);


    // Webpage Tag 156559 / Compona Webpage Pixel / Selfcampaign
    /*
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    // loading script crashes on ie therefore abort
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))
    {
      return;
    }
    var myrtbHost = (("https:" == document.location.protocol) ? "https://lib." : "http://lib.");
    var myrtbScript = document.createElement("script");myrtbScript.type = "text/javascript";myrtbScript.async = true;
    myrtbScript.src=myrtbHost+"selfcampaign.com/tag/156559_14dc46da46ee50901459cccff5263b60?async=1";
    (document.getElementsByTagName("head")[0]||document.getElementsByTagName("body")[0]).appendChild(myrtbScript);
    */
  }
};

