import { ProductActionTypes } from "./ActionTypes";
import { routerActions } from "connected-react-router";
import COMPONA_WidgetServer_JSON_Product from "../../common/services/COMPONA_WidgetServer_JSON_Product";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { LayoutActions } from "../../layout/actions/layoutActions";
import { createProductUrl } from "../../common/utils/createUrls";
import { createPopMessage } from "../../popMessage/actions/popMessageActions";
import { PopMessageType } from "../../common/constants/Constants";

export const ProductActions = {
  /**
   * @param productData
   * @returns {(dispatch, getState) => Promise<void>}
   */
  setInitialData: function(productData) {
    return async (dispatch, getState) => {
      dispatch(LayoutActions.openSearch(false));
      const productState = getState().product;
      if (productState.data === null || productState.data.objectID !== productData.objectID) {
        dispatch(ProductActions.setProductData(productData));
      }
    };
  },

  setProductData: function(productData) {
    return { type: ProductActionTypes.SET_PRODUCT_DATA, payload: { productData } };
  },

  setRelatedTabData: function(objectID, productRelationType, data) {
    return {
      type: ProductActionTypes.SET_RELATED_TAB_DATA,
      payload: { objectID, productRelationType, data },
    };
  },

  setRelatedTabActiveIndex: function(productRelationType) {
    return {
      type: ProductActionTypes.SET_RELATED_TAB_ACTIVE_INDEX,
      payload: { productRelationType },
    };
  },

  onClickFoldingBoxTitle: function(attributeSectionId) {
    return { type: ProductActionTypes.OPEN_CLOSE_FOLDING_BOX, payload: { attributeSectionId } };
  },

  onClickTakeChange: function(direction: string) {
    return { type: ProductActionTypes.TAKE_COUNT_CLICK_CHANGE, payload: { direction } };
  },

  onInputTakeChange: function(quantityString: string) {
    return { type: ProductActionTypes.TAKE_COUNT_INPUT_CHANGE, payload: { quantityString } };
  },

  setProductLinkAlternates: function(data) {
    return { type: ProductActionTypes.SET_PRODUCT_LINK_ALTERNATES, payload: { data } };
  },

  setProductFilesMsg: function(msg: string) {
    return { type: ProductActionTypes.SET_PRODUCT_FILES_MSG, payload: { msg } };
  },

  loadFirstTabData: function() {
    return async (dispatch, getState) => {
      const productState = getState().product;
      if (productState.data) {
        if (productState.data.productRelationTypes && productState.data.objectID) {
          if (
            productState.data.productRelationTypes.length > 0 &&
            productState.relatedTabActiveIndex === 0
          ) {
            if (productState.relatedTabs && productState.relatedTabs.length > 0) {
              if (productState.relatedTabs[0].isLoaded === false) {
                dispatch(
                  ProductActions.onTabIdChanged(
                    productState.data.objectID,
                    productState.data.productRelationTypes[0],
                  ),
                );
              }
            }
          }
        }
      }
    };
  },

  /**
   * Get Newest Products for Home include Debounce
   * @returns {any}
   */
  getProductData: function(objectID) {
    return async (dispatch, getState) => {
      const response = await COMPONA_WidgetServer_JSON_Product.getProductData(objectID, "");
      dispatch(ProductActions.setProductData(response.data[activeLang()]));
      dispatch(ProductActions.setProductLinkAlternates(response.data));
      dispatch(
        routerActions.replace(
          createProductUrl(response.data[activeLang()], activeLang()) + window.location.search,
        ),
      );

      if (
        response.data[activeLang()] &&
        response.data[activeLang()].productRelationTypes &&
        response.data[activeLang()].productRelationTypes.length > 0
      ) {
        dispatch(ProductActions.loadFirstTabData());
      }
    };
  },

  /**
   *
   * @param objectID
   * @param type
   * @returns {(dispatch, getState) => Promise<void>}
   */
  onTabIdChanged: function(objectID, type) {
    return async (dispatch, getState) => {
      dispatch(ProductActions.setRelatedTabActiveIndex(type));
      const response = await COMPONA_WidgetServer_JSON_Product.getRelatedProducts(objectID, type);
      dispatch(ProductActions.setRelatedTabData(objectID, type, response.data[activeLang()]));
    };
  },

  /**
   *
   * @param e
   * @param type
   */
  onClickProductFile: function(e, type) {
    return (dispatch, getState) => {
      const userState = getState().user;
      if (type === "3d_datei" && userState.isAuth === false) {
        dispatch(ProductActions.setProductFilesMsg(dic("KEIN DOWNLOAD LOGIN ZWINGEND")));
        e.preventDefault();
        return false;
      }

      dispatch(ProductActions.setProductFilesMsg(""));
    };
  },

  /**
   * Set List Sorting Column
   * @param column
   * @param direction
   * @returns {(dispatch, getState) => Promise<void>}
   */
  setSorting: function(column, direction) {
    return async (dispatch, getState) => {
      if (column.isRange === true) {
        dispatch(createPopMessage(PopMessageType.info, dic("RANGE SPALTE NICHT SORTIERBAR"), 5000));
      } else {
        dispatch(ProductActions.setSortingSettins(column, direction));
      }
    };
  },

  /**
   * Set Sorting Settings in State
   * @param hits
   * @returns {{type: ProductActionTypes}}
   */
  setSortingSettins: function(column, direction) {
    return { type: ProductActionTypes.SET_RELATED_SORTING, column, direction };
  },
};
