import "./polyfill";
import * as React from "react";
import Layout from "./layout/containers/Layout";
import { homeReducers } from "./home/reducers";
import { layoutReducers } from "./layout/reducers";
import { catalogReducers } from "./catalog/reducers";
import { productReducers } from "./product/reducers";
import { cartReducers } from "./cart/reducers";
import { wishlistReducers } from "./wishlist/reducers";
import { signupReducers } from "./signup/reducers";
import { accountReducers } from "./account/reducers";
import { checkoutReducers } from "./checkout/reducers";
import { orderReducers } from "./order/reducers";
import { manageReducers } from "./manage/reducers";
import { userReducers } from "./user/reducers";
import { filterPanelReducers } from "./filterPanel/reducers";
import { popMessageReducers } from "./popMessage/reducers";

import ElstrBaseApp from "elstr-jslib-4/dist/ElstrBaseApp";
import ElstrIo from "elstr-jslib-4/dist/ElstrIo";
import { initStore, State } from "./State";

import { searchReducers } from "./search/reducers";
import AlgoliaServices from "./common/services/AlgoliaServices";

import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { SearchActions } from "./search/actions/searchActions";
import { UserActions } from "./user/actions/userActions";
import { parseUrlToSearchState } from "./common/utils/parseHashUrl";

import { cmsReducers } from "./cms/reducers";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { config } from "elstr-jslib-4/dist/ElstrConfig";
import { ListActions } from "./list/actions/listActions";
import { ProductActions } from "./product/actions/productActions";

import * as Sentry from "@sentry/browser";
import { ELSTR_BACKEND_VERSION } from "elstr-jslib-4/dist/constants/BackendVersion";
import { TagmanagerUtils } from "./common/utils/tagmanagerUtils";

declare global {
  interface Window {
    ELSTR: any; // TODO: replace any with elstr interface
    COMPONA_STATIC_CACHE: any;
    dataLayer: any;
  }
}

class Index extends ElstrBaseApp {
  constructor() {
    super();

    /* Set the Service URL */
    const serviceUrl = config("serviceUrl");
    ElstrIo.SERVICE_URL = serviceUrl !== null ? serviceUrl.elstr : "/services/";
    ElstrBaseApp.ELSTR_BACKEND_VERSION = ELSTR_BACKEND_VERSION.BE1;

    /* Disable Error Messages if the string key is not there  */
    this.language.disableErrors();

    /* Call an async Boot function (async is not possible in constructor) */
    this.bootApp();

    let sentryConfigDsn = "";
    if (window.COMPONA_STATIC_CACHE.sentry && window.COMPONA_STATIC_CACHE.sentry.active === "1") {
      const sentryConfig = window.COMPONA_STATIC_CACHE.sentry;
      sentryConfigDsn = `https://${sentryConfig.public_key}@sentry.io/${sentryConfig.project_id}`;
    }
    Sentry.init({ dsn: sentryConfigDsn, environment: window.ELSTR.applicationEnv });
  }

  /**
   * Boot function
   * @returns {Promise<void>}
   */
  async bootApp() {
    // Application initState
    const appState: State = initStore;

    // Elstr User State
    // Overwrite with the window.ELSTR.applicationData.user the initStore
    appState.user = { ...appState.user, ...window.ELSTR.applicationData.user };

    // Wipe the config data from the browser
    window.ELSTR.applicationData.user = null;

    // Get All Facets
    const facets: any = await AlgoliaServices.getAllFacets(activeLang());
    appState.layout.facets = facets.facets;

    // Get All Classifications
    const staticCacheClassifications = window.COMPONA_STATIC_CACHE.allClassifications[activeLang()];

    // Filter Treedata
    const group = parseUrlToSearchState(window.location.toString(), staticCacheClassifications);
    appState.search.group = group;
    const trees = {};
    if (group) {
      // trees[group.classificationId] = new Array(group.objectID);
      const objs = {};
      objs[group.objectID] = group.parentIds;
      trees[group.classificationId] = objs;
    }
    appState.search.trees = trees;

    // Init Tagmanager
    TagmanagerUtils.init();

    // Add Reducers and setup Redux /  HMR (hot module replacement)
    this.setup(
      [
        homeReducers,
        layoutReducers,
        searchReducers,
        catalogReducers,
        productReducers,
        userReducers,
        cartReducers,
        wishlistReducers,
        signupReducers,
        accountReducers,
        checkoutReducers,
        orderReducers,
        manageReducers,
        filterPanelReducers,
        cmsReducers,
        popMessageReducers,
        { algoliaData: (state = {}) => state },
      ],
      appState,
      <Layout />,
      [],
    );

    // Init UserData and Lists
    if (appState.user.isAuth) {
      // @ts-ignore
      ElstrCore.store.dispatch(UserActions.initComponaUserAndLists());
    } else {
      // @ts-ignore
      await ElstrCore.store.dispatch(ListActions.initAnonLists());
      ElstrCore.store.dispatch(UserActions.setUserData({ hasInitLists: true }));
    }

    // Init ProductData if it is a product request
    if (
      window.COMPONA_STATIC_CACHE.initialProductData &&
      window.COMPONA_STATIC_CACHE.initialProductData[activeLang()]
    ) {
      ElstrCore.store.dispatch(
        // @ts-ignore
        ProductActions.setInitialData(window.COMPONA_STATIC_CACHE.initialProductData[activeLang()]),
      );
      delete window.COMPONA_STATIC_CACHE.initialProductData;
    }

    // Dispatch function on URL change
    ElstrCore.history.listen(location => {
      ElstrCore.store.dispatch(SearchActions.parseURL(location));
    });
  }
}

new Index();
